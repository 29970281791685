<template>
  <div>
    <router-view :key="$route.fullPath" />
    <transition name="fade-transition">
      <div
        class="global-alert"
        v-if="getShowAlert"
      >
        <Alert
          :type="getAlertType"
          :text="getAlertText"
        />
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { Alert } from 'ff-components-lib';

export default {
  name: 'App',
  components: {
    Alert,
  },
  computed: {
    ...mapGetters(['getShowAlert', 'getAlertText', 'getAlertType', 'getUser']),
  },
  mounted() {
    const cookietbotScript = document.createElement('script');
    if (this.getUser) {
      this.$store.dispatch('storeToken');
    }
    let firstScript = document.getElementsByTagName('script')[0];

    cookietbotScript.type = 'text/javascript';
    cookietbotScript.src = 'https://consent.cookiebot.com/uc.js?cbid=1c7a39c6-14c7-47b0-8b84-903ad4fed605';
    cookietbotScript.id = 'Cookiebot';
    cookietbotScript['data-cbid'] = '1c7a39c6-14c7-47b0-8b84-903ad4fed605';
    cookietbotScript['data-blockingmode'] = 'auto';

    firstScript.parentNode.insertBefore(cookietbotScript, firstScript);

    firstScript = document.getElementsByTagName('script')[0];
    const tagManagerScript = document.createElement('script');
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    tagManagerScript.async = true;
    tagManagerScript.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-5CZB7TL&l=dataLayer';
    firstScript.parentNode.insertBefore(tagManagerScript, firstScript);
  },
};
</script>
<style lang="scss">
@import '@/assets/sass/main.scss';

.global-alert {
  z-index: 2000;
  position: fixed;
  top: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
