import httpClient from './httpClient';

const AGENT_URL = '/agents';
const AGENT_MESSAGES_URL = '/agent-messages';

async function getAgents(params) {
  const body = await httpClient.get(`${AGENT_URL}`, { params });
  const { data } = body.data;

  return { data, totalCount: Number(body.headers['x-total-count']) };
}

async function getAgentMessages(params) {
  const body = await httpClient.get(`${AGENT_MESSAGES_URL}`, { params });
  const { data } = body.data;

  return { data, totalCount: Number(body.headers['x-total-count']) };
}

export { getAgents, getAgentMessages };
