const defaultState = {
  showSidebar: false,
  showUserSidebar: false,
};

const getters = {
  getShowSidebar: (state) => state.showSidebar,
  getShowUserSidebar: (state) => state.showUserSidebar,
};

const mutations = {
  setShowSidebar: (state, showSidebar) => { state.showSidebar = showSidebar; },
  setShowUserSidebar: (state, showUserSidebar) => { state.showUserSidebar = showUserSidebar; },
};

const actions = {
  storeShowSidebar({ commit }, showSidebar) {
    commit('setShowSidebar', showSidebar);
  },
  toggleShowSidebar({ commit, state }) {
    commit('setShowSidebar', !state.showSidebar);
  },
  storeShowUserSidebar({ commit }, showUserSidebar) {
    commit('setShowUserSidebar', showUserSidebar);
  },
  toggleShowUserSidebar({ commit, state }) {
    commit('setShowUserSidebar', !state.showUserSidebar);
  },
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
