const processesRoutes = [
  {
    path: '/process/sell-us',
    name: 'sell-us-process',
    component: () => import(/* webpackChunkName: "sell-us-process" */ '@/views/Processes/SellUsProcessScreen.vue'),
  },
  {
    path: '/process/rent',
    name: 'rent-process',
    component: () => import(/* webpackChunkName: "rent-process" */ '@/views/Processes/RentProcessScreen.vue'),
  },
  {
    path: '/process/rent/:id',
    name: 'rent-process-edit',
    component: () => import(/* webpackChunkName: "rent-process" */ '@/views/Processes/RentProcessScreen.vue'),
  },
];

export default processesRoutes;
