const externalRoutes = [
  {
    path: '/',
    name: 'sell',
    component: () => import(
      /* webpackChunkName: "sell" */ '@/views/External/Info/SellScreen.vue'
    ),
  },
  {
    path: '/rent',
    name: 'rent',
    component: () => import(
      /* webpackChunkName: "rent" */ '@/views/External/Info/RentScreen.vue'
    ),
  },
  {
    path: '/agents',
    name: 'agents',
    component: () => import(
      /* webpackChunkName: "agents" */ '@/views/External/Info/AgentsScreen.vue'
    ),
  },
  {
    path: '/referral',
    name: 'referral',
    component: () => import(
      /* webpackChunkName: "referral" */ '@/views/External/Info/ReferralScreen.vue'
    ),
  },
  {
    path: '/properties',
    name: 'properties',
    component: () => import(
      /* webpackChunkName: "properties" */ '@/views/External/Properties/PropertiesScreen.vue'
    ),
  },
  {
    path: '/property/:id',
    name: 'property-detail',
    props: true,
    component: () => import(
      /* webpackChunkName: "property-detail" */ '@/views/External/Properties/PropertyDetailScreen.vue'
    ),
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => import(
      /* webpackChunkName: "sign-in" */ '@/views/External/SignInScreen.vue'
    ),
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import(
      /* webpackChunkName: "sign-up" */ '@/views/External/SignUpScreen.vue'
    ),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(
      /* webpackChunkName: "forgot-password" */ '@/views/External/ForgotPasswordScreen.vue'
    ),
  },
  {
    path: '/cookies-policy',
    name: 'cookies-policy',
    component: () => import(
      /* webpackChunkName: "cookies-policy" */ '@/views/External/CookiesPolicyScreen.vue'
    ),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(
      /* webpackChunkName: "privacy-policy" */ '@/views/External/PrivacyPolicyScreen.vue'
    ),
  },
  {
    path: '/blog',
    beforeEnter() {
      window.location.href = 'https://blog.firstfloor.pt';
    },
  },
  {
    path: '/facebook',
    beforeEnter() {
      window.location.href = 'https://www.facebook.com/firstfloorportugal';
    },
  },
  {
    path: '/instagram',
    beforeEnter() {
      window.location.href = 'https://www.instagram.com/firstfloorportugal';
    },
  },
  {
    path: '/linkedin',
    beforeEnter() {
      window.location.href = 'https://www.linkedin.com/company/firstfloorportugal';
    },
  },
];

export default externalRoutes;
