import Vue from 'vue';
import './registerServiceWorker';
// eslint-disable-next-line no-unused-vars
import libStyles from 'ff-components-lib/dist/style.css';
import { ExpandTransition } from 'ff-components-lib';
import router from './router';
import store from './store/index';
import '@mdi/font/css/materialdesignicons.css';
import i18n from '@/plugins/i18n';
import App from './App.vue';

import 'leaflet/dist/leaflet.css';
// console.log(libStyles);

Vue.config.productionTip = false;

Vue.component('expand-transition', ExpandTransition);

new Vue({
  router,
  store,
  i18n,
  mounted() {
    store.dispatch('updateBreakpoints');

    window.addEventListener(
      'resize',
      () => {
        window.setTimeout(() => { store.dispatch('updateBreakpoints') }, 200)
      },
      { passive: true },
    );
  },
  render: (h) => h(App),
}).$mount('#app');
