import httpClient from './httpClient';

const PROPERTIES_URL = '/properties';

async function getProperties(params) {
  const body = await httpClient.get(`${PROPERTIES_URL}`, { params });
  const { data } = body.data;

  return { data, totalCount: Number(body.headers['x-total-count']) };
}

async function getProperty(id) {
  const body = await httpClient.get(`${PROPERTIES_URL}/${id}`);
  return body.data;
}

async function createProperty({ property, images, documents }) {
  const formData = new FormData();
  await Promise.all(
    images.map((image) => {
      formData.append('images', image);
    }),
  );

  if (documents.buildingPassbook) {
    formData.append('buildingPassbook', documents.buildingPassbook);
  }
  if (documents.cc) {
    formData.append('cc', documents.cc);
  }
  if (documents.energeticCertificate) {
    formData.append('energeticCertificate', documents.energeticCertificate);
  }

  formData.append('property', JSON.stringify(property));

  return httpClient.post(`${PROPERTIES_URL}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

async function updateProperty({ property, images, documents }) {
  const formData = new FormData();
  await Promise.all(
    images.map((image) => {
      formData.append('images', image);
    }),
  );

  if (documents.buildingPassbook) {
    formData.append('buildingPassbook', documents.buildingPassbook);
  }
  if (documents.cc) {
    formData.append('cc', documents.cc);
  }
  if (documents.energeticCertificate) {
    formData.append('energeticCertificate', documents.energeticCertificate);
  }

  formData.append('property', JSON.stringify(property));

  return httpClient.put(`${PROPERTIES_URL}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

async function getPropertyDocument(id, file, type) {
  const body = await httpClient.get(`${PROPERTIES_URL}/${id}/file`, {
    params: { file, type },
    responseType: 'blob',
  });

  return { file: body.data, fileType: body.headers['x-file-type'] }
}

async function uploadPropertyDocument(id, type, document) {
  const formData = new FormData();
  formData.append('document', document);

  formData.append('type', type);

  return httpClient.post(`${PROPERTIES_URL}/${id}/file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

async function getUserFavorites(user, params) {
  const body = await httpClient.get(`${PROPERTIES_URL}/favorites/${user}`, { params });
  const { data } = body.data;

  return { data, totalCount: Number(body.headers['x-total-count']) };
}

export {
  getProperties,
  getProperty,
  createProperty,
  updateProperty,
  getPropertyDocument,
  uploadPropertyDocument,
  getUserFavorites,
};
