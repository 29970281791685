const internalRoutes = [
  {
    path: '/dashboard',
    name: 'dashboard-profile',
    component: () => import(/* webpackChunkName: "dashboard-profile" */ '@/views/Dashboard/ProfileScreen.vue'),
  },
  {
    path: '/dashboard/listings',
    name: 'dashboard-listings',
    component: () => import(/* webpackChunkName: "dashboard-listings" */ '@/views/Dashboard/ListingsScreen.vue'),
  },
  {
    path: '/dashboard/favorites',
    name: 'dashboard-favorites',
    component: () => import(/* webpackChunkName: "dashboard-favorites" */ '@/views/Dashboard/FavoritesScreen.vue'),
  },
  {
    path: '/dashboard/listings/:id',
    name: 'dashboard-listing-detail',
    component: () => import(/* webpackChunkName: "dashboard-listing-detail" */ '@/views/Dashboard/ListingDetailScreen.vue'),
  },
  {
    path: '/dashboard/test',
    name: 'dashboard-test',
    component: () => import(/* webpackChunkName: "dashboard-test" */ '@/views/Dashboard/TestScreen.vue'),
  },
];

export default internalRoutes;
