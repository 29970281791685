import Vue from 'vue';
import VueRouter from 'vue-router';
import externalRoutes from './external-routes';
import processesRoutes from './processes-routes';
import internalRoutes from './internal-routes';
import store from '@/store/index';

Vue.use(VueRouter);

const routes = [
  ...externalRoutes,
  ...processesRoutes,
  ...internalRoutes,
  {
    path: '*',
    name: 'not-found',
    component: () => import(
      /* webpackChunkName: "not-found" */ '@/views/External/NotFoundScreen.vue'
    ),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  const toPath = to.path === '/' ? to.path : to.matched[0].path;

  const user = store.getters.getUser;
  if (user) {
    if (
      toPath === '/sign-in'
      || toPath === '/sign-up'
      || toPath === '/forgot-password'
    ) {
      return next({ path: '/' });
    }

    const internalRoutesPaths = internalRoutes.map((route) => route.path);
    if (internalRoutesPaths.includes(toPath)) {
      store.dispatch('storeBreadcrumb', to);
    }

    return next();
  }

  const externalRoutesPaths = externalRoutes.map((route) => route.path);
  if (externalRoutesPaths.includes(toPath) || toPath === '/process/sell-us') {
    return next();
  }

  return next({
    name: 'sign-in',
    params: { redirect: true, name: to.name, params: to.params },
  });
});

export default router;
