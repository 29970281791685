// import httpClient from '@/services/httpClient';
// import i18n from '@/plugins/i18n';

function getClientWidth() {
  if (typeof document === 'undefined') return 0
  return Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0,
  )
}

function getClientHeight() {
  if (typeof document === 'undefined') return 0
  return Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0,
  )
}

const breadcrumbMap = {
  dashboard: 'My FirstFloor',
  listings: 'Listagens',
  favorites: 'Favoritos',
};

const defaultState = {
  loading: false,
  breadcrumb: [{ name: 'My FirstFloor', route: '/dashboard' }, { name: 'Listagens', route: '/dashboard' }],
  showAlert: false,
  alertText: '',
  alertType: '',
  breakpoints: {
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920,
    },
  },
};

const getters = {
  getLoading: (state) => state.loading,
  getBreadcrumb: (state) => state.breadcrumb,
  getShowAlert: (state) => state.showAlert,
  getAlertText: (state) => state.alertText,
  getAlertType: (state) => state.alertType,
  getBreakpoints: (state) => state.breakpoints,
};

const mutations = {
  setLoading: (state, loading) => { state.loading = loading; },
  setBreadcrumb: (state, breadcrumb) => {
    state.breadcrumb = breadcrumb;
  },
  setShowAlert: (state, showAlert) => {
    state.showAlert = showAlert;
  },
  setAlertText: (state, alertText) => {
    state.alertText = alertText;
  },
  setAlertType: (state, alertType) => {
    state.alertType = alertType;
  },
  setBreakpoints: (state, breakpoints) => {
    state.breakpoints = breakpoints;
  },
};

const actions = {
  storeLoading({ commit }, loading) {
    commit('setLoading', loading);
  },
  storeBreadcrumb({ commit }, route) {
    const { path } = route.matched[0];
    const pathArray = path.split('/');
    pathArray.splice(0, 1);
    const breadcrumb = [];

    pathArray.map(async (page, index) => {
      const breadcrumbPath = route.path
        .split('/')
        .slice(0, index + 2)
        .join('/');
      breadcrumb.push({ name: breadcrumbMap[page], route: breadcrumbPath });
    });
    commit('setBreadcrumb', breadcrumb);
  },
  displayAlert({ commit }, { text, type }) {
    commit('setAlertText', text);
    commit('setAlertType', type);
    commit('setShowAlert', true);
    setTimeout(() => {
      commit('setAlertText', '');
      commit('setAlertType', '');
      commit('setShowAlert', false);
    }, 5000);
  },
  updateBreakpoints({ commit }) {
    const breakpoints = {
      thresholds: {
        xs: 600,
        sm: 960,
        md: 1280,
        lg: 1920,
      },
    };
    const height = getClientHeight();
    const width = getClientWidth();

    const xs = width < breakpoints.thresholds.xs;
    const sm = width < breakpoints.thresholds.sm && !xs;
    const md = width < breakpoints.thresholds.md && !(sm || xs);
    const lg = width < breakpoints.thresholds.lg && !(md || sm || xs);
    const xl = width >= breakpoints.thresholds.lg;

    breakpoints.height = height;
    breakpoints.width = width;

    breakpoints.xs = xs
    breakpoints.sm = sm
    breakpoints.md = md
    breakpoints.lg = lg
    breakpoints.xl = xl

    breakpoints.smAndDown = (xs || sm) && !(md || lg || xl)
    breakpoints.smAndUp = !xs && (sm || md || lg || xl)
    breakpoints.mdAndDown = (xs || sm || md) && !(lg || xl)
    breakpoints.mdAndUp = !(xs || sm) && (md || lg || xl)
    breakpoints.lgAndDown = (xs || sm || md || lg) && !xl
    breakpoints.lgAndUp = !(xs || sm || md) && (lg || xl)

    switch (true) {
      case (xs):
        breakpoints.name = 'xs'
        break
      case (sm):
        breakpoints.name = 'sm'
        break
      case (md):
        breakpoints.name = 'md'
        break
      case (lg):
        breakpoints.name = 'lg'
        break
      default:
        breakpoints.name = 'xl'
        break
    }

    commit('setBreakpoints', breakpoints);
  },
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
