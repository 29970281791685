import {
  signUp,
  signIn,
  googleSignIn,
  facebookSignIn,
  forgotPassword,
  updateUser,
  updateUserFavorites,
} from '@/services/auth';
import httpClient from '@/services/httpClient';

const defaultState = {
  user: undefined,
  jwtToken: undefined,
};

const getters = {
  getUser: (state) => state.user,
  getJWTToken: (state) => state.jwtToken,
};

const mutations = {
  setUser: (state, user) => {
    state.user = user;
  },
  setJWTToken: (state, jwtToken) => {
    state.jwtToken = jwtToken;
  },
};

const actions = {
  storeToken({ state }) {
    httpClient.defaults.headers.common.Authorization = `Bearer ${state.jwtToken
      || ''}`;
  },
  async signUp({ commit, dispatch }, params) {
    const { data } = await signUp(params);

    commit('setUser', data.user);
    commit('setJWTToken', data.token);

    dispatch('storeToken');
  },
  async signIn({ commit, dispatch }, params) {
    const { data } = await signIn(params);

    commit('setUser', data.user);
    commit('setJWTToken', data.token);

    dispatch('storeToken');
  },
  async googleSignIn({ commit, dispatch }, googleResp) {
    const { data } = await googleSignIn(googleResp);

    commit('setUser', data.user);
    commit('setJWTToken', data.token);

    dispatch('storeToken');
  },
  async facebookSignIn({ commit, dispatch }, fbResp) {
    const { data } = await facebookSignIn(fbResp);

    commit('setUser', data.user);
    commit('setJWTToken', data.token);

    dispatch('storeToken');
  },
  async forgotPassword(_, params) {
    await forgotPassword(params);
  },
  async signOut({ commit }) {
    // const resp = await signOut(params);

    commit('setUser', undefined);
    commit('setJWTToken', undefined);

    httpClient.defaults.headers.common.Authorization = '';
  },
  async updateUser({ commit }, user) {
    try {
      const { data } = await updateUser(user);
      commit('setUser', data.user);
    } catch (err) {
      console.error('Could not update user', err);
      throw err;
    }
  },
  async updateUserFavorites({ commit, state }, { user, property }) {
    try {
      await updateUserFavorites(user, property);

      const favorites = [...state.user.favorites];

      if (!favorites.includes(property)) {
        favorites.push(property);
      } else {
        favorites.splice(favorites.indexOf(property), 1);
      }

      commit('setUser', { ...state.user, favorites });
    } catch (err) {
      console.error('Could not update user favorites', err);
      throw err;
    }
  },
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
