import {
  sendReferralEmail,
  sendSellUsEmail,
  sendRentEmail,
  sendAgentMessageEmail,
  sendAgentWaitingListEmail,
  sendPropertyContactEmail,
  sendPropertyVisitEmail,
  sendPropertyRentEmail,
} from '@/services/mailling';

const defaultState = {};

const getters = {};

const mutations = {};

const actions = {
  async sendReferralEmail(_, fields) {
    try {
      await sendReferralEmail(fields);
    } catch (err) {
      console.error('Could not send referral email');
      throw err;
    }
  },
  async sendSellUsEmail(_, fields) {
    try {
      await sendSellUsEmail(fields);
    } catch (err) {
      console.error('Could not send sell us email');
      throw err;
    }
  },
  async sendRentEmail(_, fields) {
    try {
      await sendRentEmail(fields);
    } catch (err) {
      console.error('Could not send rent email');
      throw err;
    }
  },
  async sendAgentMessageEmail(_, fields) {
    try {
      await sendAgentMessageEmail(fields);
    } catch (err) {
      console.error('Could not send agent messages email');
      throw err;
    }
  },
  async sendAgentWaitingListEmail(_, fields) {
    try {
      await sendAgentWaitingListEmail(fields);
    } catch (err) {
      console.error('Could not send agent waiting list email');
      throw err;
    }
  },
  async sendPropertyContactEmail(_, { id, fields }) {
    try {
      await sendPropertyContactEmail(id, fields);
    } catch (err) {
      console.error('Could not send property contact email');
      throw err;
    }
  },
  async sendPropertyVisitEmail(_, { id, fields }) {
    try {
      await sendPropertyVisitEmail(id, fields);
    } catch (err) {
      console.error('Could not send property visit email');
      throw err;
    }
  },
  async sendPropertyRentEmail(_, { id, fields }) {
    try {
      await sendPropertyRentEmail(id, fields);
    } catch (err) {
      console.error('Could not send property rent email');
      throw err;
    }
  },
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
