import {
  getAgentMessages,
} from '@/services/agents';

const defaultState = {
  agentMessages: [],
};

const getters = {
  getAgentMessages: (state) => state.agentMessages,
};

const mutations = {
  setAgentMessages(state, agentMessages) {
    state.agentMessages = agentMessages;
  },
};

const actions = {
  async storeAgentMessages({ commit }) {
    try {
      const params = {
        limit: 5,
        offset: 0,
      };

      const { data, totalCount } = await getAgentMessages(params);

      commit('setAgentMessages', Object.freeze(data.agentMessages));

      return totalCount;
    } catch (err) {
      console.error('Could not fetch agent messages from backend');
      console.error(err);
      return null;
    }
  },
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
