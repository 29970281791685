import httpClient from './httpClient';

const AUTH_URL = '/auth';
const USERS_URL = '/users';

async function signUp(params) {
  const resp = await httpClient.post(`${AUTH_URL}/sign-up`, params);

  return resp.data;
}

async function signIn(params) {
  const resp = await httpClient.post(`${AUTH_URL}/sign-in`, params);

  return resp.data;
}

async function googleSignIn(googleResp) {
  const resp = await httpClient.post(`${AUTH_URL}/sign-in/google`, googleResp);

  return resp.data;
}

async function facebookSignIn(fbResp) {
  const resp = await httpClient.post(`${AUTH_URL}/sign-in/facebook`, fbResp);

  return resp.data;
}

async function forgotPassword(params) {
  await httpClient.post(`${AUTH_URL}/forgot-password`, params);
}

async function updateUser(user) {
  const resp = await httpClient.put(`${USERS_URL}`, user);

  return resp.data;
}

async function updateUserFavorites(user, propertyId) {
  const resp = await httpClient.post(`${USERS_URL}/${user}/favorites`, {
    propertyId,
  });

  return resp.data;
}

export {
  signUp,
  signIn,
  googleSignIn,
  facebookSignIn,
  forgotPassword,
  updateUser,
  updateUserFavorites,
};
