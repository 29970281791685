import {
  getProperties,
  getProperty,
  createProperty,
  updateProperty,
  getPropertyDocument,
  uploadPropertyDocument,
  getUserFavorites,
} from '@/services/properties';

const defaultState = {
  properties: [],
};

const getters = {
  getProperties: (state) => state.properties,
};

const mutations = {
  setProperties(state, properties) {
    state.properties = properties;
  },
};

const actions = {
  async storeProperties({ commit }, { query, limit }) {
    try {
      const params = {
        limit,
        offset: 0,
        ...query,
      };

      const { data, totalCount } = await getProperties(params);

      commit('setProperties', Object.freeze(data.properties));

      return totalCount;
    } catch (err) {
      console.error('Could not fetch properties from backend');
      console.error(err);
      return null;
    }
  },
  async getProperty(_, id) {
    try {
      const { data } = await getProperty(id);
      return data;
    } catch (err) {
      console.error('Could not fetch property from backend');
      return null;
    }
  },
  async getSimilarProperties(_, id) {
    try {
      const params = {
        limit: 3,
        offset: 0,
      };

      console.log(id);

      const { data } = await getProperties(params);

      return data;
    } catch (err) {
      console.error('Could not fetch similar properties from backend');
      return null;
    }
  },
  async createProperty(_, property) {
    try {
      const { data } = await createProperty(property);
      return data;
    } catch (err) {
      console.error('Could not create property', err);
      throw err;
    }
  },
  async updateProperty(_, property) {
    try {
      const { data } = await updateProperty(property);
      return data;
    } catch (err) {
      console.error('Could not update property', err);
      throw err;
    }
  },
  async getPropertyDocument(_, { id, fileName, type }) {
    try {
      const { file, fileType } = await getPropertyDocument(id, fileName, type);
      return { file, fileType };
    } catch (err) {
      console.error('Could not fetch property document from backend');
      return null;
    }
  },
  async uploadPropertyDocument(_, { id, type, document }) {
    try {
      return await uploadPropertyDocument(id, type, document);
    } catch (err) {
      console.error('Could not fetch property document from backend');
      return null;
    }
  },
  async storeUserFavorites({ commit }, user) {
    try {
      const params = {
        limit: 10,
        offset: 0,
      };

      const { data, totalCount } = await getUserFavorites(user, params);

      commit('setProperties', Object.freeze(data.properties));

      return totalCount;
    } catch (err) {
      console.error('Could not fetch user favorites from backend');
      console.error(err);
      return null;
    }
  },
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
