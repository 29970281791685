import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import sidebar from './modules/sidebar';
import global from './modules/global';
import auth from './modules/auth';
import mailling from './modules/mailling';
import agents from './modules/agents';
import properties from './modules/properties';

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  storage: sessionStorage,
  reducer: (state) => ({
    global: state.global,
    sidebar: state.sidebar,
    auth: state.auth,
  }),
});

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    sidebar,
    global,
    auth,
    mailling,
    agents,
    properties,
  },
  plugins: [vuexPersist.plugin],
});
