import axios from 'axios';

const httpClient = axios.create({
  baseURL: `${process.env.VUE_APP_SERVICES_URL}/api`,
  headers: {
    Key: `${process.env.VUE_APP_API_KEY}`,
  },
});

export default httpClient;
