import httpClient from './httpClient';

const MAILLING_URL = '/email';

async function sendReferralEmail(fields) {
  return httpClient.post(`${MAILLING_URL}/referral`, fields);
}

async function sendSellUsEmail({ proposal, files }) {
  const formData = new FormData();
  await Promise.all(
    files.map((file) => {
      formData.append('files', file);
    }),
  );
  await Promise.all(
    Object.keys(proposal).map((key) => {
      if (proposal[key]) {
        formData.append(key, proposal[key]);
      }
    }),
  );

  await httpClient.post(`${MAILLING_URL}/sell-us`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

async function sendRentEmail(fields) {
  return httpClient.post(`${MAILLING_URL}/rent`, fields);
}

async function sendAgentMessageEmail(fields) {
  return httpClient.post(`${MAILLING_URL}/agents`, fields);
}

async function sendAgentWaitingListEmail(fields) {
  return httpClient.post(`${MAILLING_URL}/join-us`, fields);
}

async function sendPropertyContactEmail(id, fields) {
  return httpClient.post(`${MAILLING_URL}/property/${id}/contact`, fields);
}

async function sendPropertyVisitEmail(id, fields) {
  return httpClient.post(`${MAILLING_URL}/property/${id}/visit`, fields);
}

async function sendPropertyRentEmail(id, fields) {
  return httpClient.post(`${MAILLING_URL}/property/${id}/rent`, fields);
}

export {
  sendReferralEmail,
  sendSellUsEmail,
  sendRentEmail,
  sendAgentMessageEmail,
  sendAgentWaitingListEmail,
  sendPropertyContactEmail,
  sendPropertyVisitEmail,
  sendPropertyRentEmail,
};
